import {AuthServiceAdapter, AuthServiceResponse} from "./AuthServiceAdapter";
import {APIInfo} from "../Api";


export class CCSAuthService extends AuthServiceAdapter {

    constructor() {
        const url = (typeof process.env.REACT_APP_API_ACCOUNT_CSS != "undefined") ? process.env.REACT_APP_API_ACCOUNT_CSS : 'undefined';
        const apiToken = (typeof process.env.REACT_APP_API_ACCOUNT_TOKEN != "undefined") ? process.env.REACT_APP_API_ACCOUNT_TOKEN : 'undefined';

        super(url, apiToken);
    }

    /**
     *
     * @param username
     * @param password
     * @param redirectUrl
     * @param language
     * @returns
     */
    public signIn(username: string, password: string, redirectUrl: string, language: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo(
            '/api/v1/authentication/signin',
            {
                'Authorization': `Basic ` + btoa(`${username}:${password}`)
            });

        const body =
            {
                "userid": username,
                "password": password,
                redirectUrl,
                "languageIsoCode": language
            }

        return fetch(info.url, {
            method: 'POST',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then((data: Response) => {
            return data.json();
        })
    }

    /**
     * Forgot Password
     * @param email
     * @param redirectUrl
     * @param language
     * @returns
     */
    public forgotPasswordEmail(email: string, redirectUrl: string, language: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo('/api/v1/authentication/forgotpassword');

        const body = {
            email: email,
            redirectUrl: redirectUrl,
            language: language
        }

        return fetch(info.url, {
            method: 'POST',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then((data: Response) => {
            return data.json();
        })
    }

    /**
     * Update Password
     * @param token
     * @param password
     * @param redirectUrl
     * @returns
     */
    public passwordUpdate(token: string, password: string, redirectUrl: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo('/api/v1/authentication/changensppassword');
        const body = {
            token: token,
            newPassword: password,
            redirectUrl
        }

        return fetch(info.url, {
            method: 'PUT',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then(data => {
            return data.json();
        })
    }

    /**
     * Change Password
     * @param email
     * @param old_password
     * @param new_password
     * @param redirectUrl
     * @returns
     */
    public changePassword(email: string, old_password: string, new_password: string, redirectUrl: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo('/api/v1/authentication/updatepassword');

        const body = {
            userid: email,
            oldPassword: old_password,
            newPassword: new_password,
            redirectUrl
        }

        return fetch(info.url, {
            method: 'PUT',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then((response: Response) => {
            return response.json();
        })
    }
}