import {Translations as ParentTranslations} from "../classes/Translations";

export default class SignInTranslations extends ParentTranslations {
    readonly translation = {
        'es': {
            forgotten_password: 'Olvidaste tu contraseña?',
            change_password:'¿Cambiar la contraseña?',
            signin: 'Iniciar',
            username:'Nombre de usuario',
            password: 'Contraseña',
            email: 'Correo electrónico',
            loginFailure: 'El nombre de usuario o contraseña ingresada es incorrecta',
            loginSuccess: 'El inicio de sesión fue exitoso',
        },
        'en': {
            forgotten_password: 'Forgot your password?',
            change_password:'Change Password?',
            signin: 'Sign In',
            username: 'Username',
            password: 'Password',
            email: 'Email',
            loginFailure: 'The Username or Password entered is incorrect',
            loginSuccess: 'Sign in was Successful',
        },
        'fr': {
            forgotten_password: 'Mot de passe oublié?',
            change_password:'Changer le mot de passe?',
            signin: 'COMMENCER',
            username: 'Nom d\'usager',
            password: 'Mot de passe',
            email: 'E-mail',
            loginFailure: 'Le nom d\'utilisateur ou le mot de passe entré est incorrect',
            loginSuccess: 'La connexion a réussi',
        }

    }
}
