import { Api } from "../Api";

/**
 * The response from the authentication service.
 */
export interface AuthServiceResponse {
    userProfile: {
        email: string,
        authenticationToken: string,
        accountNumber: string,
    }
    message: string,
    success: boolean,
    error: string,
    status: number,
    errorCode?: string,
}

export abstract class AuthServiceAdapter extends Api {

    /**
     * Sign in to the system.
     *
     * @param {string} username - The username of the user.
     * @param {string} password - The password of the user.
     * @param {string} redirectUrl - The URL to redirect to after signing in.
     * @param {string} language - The language preference of the user.
     * @param {string} [recaptchaToken] - The optional reCAPTCHA token.
     * @return {Promise<AuthServiceResponse>} A promise that resolves to the authentication service response.
     */
    public abstract signIn(username: string, password: string, redirectUrl: string, language: string, recaptchaToken?: string): Promise<AuthServiceResponse>;

    /**
     * invoke forgot password email
     * @param email
     * @param redirectUrl
     * @param language
     * @param recaptcha
     */
    public abstract forgotPasswordEmail(email: string, redirectUrl: string, language: string, recaptcha?: string): Promise<AuthServiceResponse>;

    /**
     * Updates the user's password after they have forgotten it.
     *
     * @param {string} token - The password reset token provided to the user.
     * @param {string} password - The new password the user wants to set.
     * @param {string} redirectUrl - The URL to redirect the user after the password is updated.
     * @param {string} [reCaptchaToken] - Optional reCaptcha token for additional verification.
     * @return {Promise<AuthServiceResponse>} A promise that resolves to an AuthServiceResponse object.
     */
    public abstract passwordUpdate(token: string, password: string, redirectUrl: string, reCaptchaToken?: string): Promise<AuthServiceResponse>;

    /**
     * invoke change password
     * @param email
     * @param old_password
     * @param new_password
     * @param redirectUrl
     * @param reCaptchaToken
     */
    public abstract changePassword(email: string, old_password: string, new_password: string, redirectUrl: string, reCaptchaToken?: string): Promise<AuthServiceResponse>;

}