import React, {useContext, useEffect} from 'react';
import {Languages} from "../Origin/Languages";
import GeneralTranslations from "../../translations/GeneralTranslations";
import {SiteSourceContext, SiteSourceContextUpdate} from "../Origin/SiteSource";
import "./Oops.scss"

export const Oops = () => {

    const translation = new GeneralTranslations();
    const updateSourceState = useContext(SiteSourceContextUpdate);
    const sourceState = useContext(SiteSourceContext)

    /**
     * Update Language Hook
     * @param language
     */
    function updateLanguage(language: Languages) {
        updateSourceState(language, undefined, sourceState.updatedViaUrl)
    }

    /**
     * Update Return URL Hook
     * @param url
     */
    function updateUrl(url: string) {
        updateSourceState(undefined, url, sourceState.updatedViaUrl)
    }

    /**
     * Page Title Management Hook
     */
    useEffect(() => {
        document.title = translation.getTranslationWithoutContext('pageTitle', sourceState.language);
    });


    return (<>
            <div className="login-container oops">
                <div className="login-box">
                    <div className="login-header">
                        <img id="main-logo"
                             alt='Natures Sunshine'
                             src="https://res.cloudinary.com/natures-sunshine/image/upload/c_scale,w_512/natures-sunshine/logos/ns-logo-color.png"/>
                    </div>

                    <div className="list-selection">
                        <div
                            className="section-title">{translation.getTranslation('language')} ({sourceState.language})
                        </div>
                        <ul>
                            <li>
                                <input onChange={() => updateLanguage(Languages.ENGLISH)}
                                       type="radio" value={Languages.ENGLISH} name="language"
                                       checked={sourceState.language === Languages.ENGLISH}/> {translation.getTranslation('english')}
                            </li>
                            <li>
                                <input onChange={() => updateLanguage(Languages.SPANISH)}
                                       type="radio" value={Languages.SPANISH} name="language"
                                       checked={sourceState.language === Languages.SPANISH}/> {translation.getTranslation('spanish')}
                            </li>
                            <li>
                                <input onChange={() => updateLanguage(Languages.FRENCH)}
                                       type="radio" value={Languages.FRENCH} name="language"
                                       checked={sourceState.language === Languages.FRENCH}/> {translation.getTranslation('french')}
                            </li>
                        </ul>


                        <div
                            className="section-title">{translation.getTranslation('country')} ({sourceState.returnUrl})
                        </div>
                        <ul>
                            <li>
                                <input
                                    onChange={() => updateUrl('naturessunshine.com')}
                                    type="radio" value="com" name="site"
                                    checked={sourceState.returnUrl === "naturessunshine.com"}/> {translation.getTranslation('usa')}

                            </li>
                            <li>
                                <input
                                    onChange={() => updateUrl('naturessunshine.mx')}
                                    type="radio" value="mx" name="site"
                                    checked={sourceState.returnUrl === "naturessunshine.mx"}/> {translation.getTranslation('mexico')}

                            </li>
                            <li>
                                <input
                                    onChange={() => updateUrl('naturessunshine.ca')}
                                    type="radio" value="ca" name="site"
                                    checked={sourceState.returnUrl === "naturessunshine.ca"}/> {translation.getTranslation('canada')}
                            </li>
                        </ul>
                    </div>

                    <a className="btn btn-primary"
                       href={'/' + sourceState.returnUrl + '/' + sourceState.language}>{translation.getTranslation('continue')}</a>

                </div>
            </div>
        </>
    )
}
