import {SiteSourceContext} from "../components/Origin/SiteSource";
import {useContext} from "react";
export abstract class Translations {

    translation = {
        'es': {},
        'en': {},
        'fr': {}
    }

    public getTranslation = (key: string) => {
        const sourceState = useContext(SiteSourceContext);
        const languageSelected = sourceState.language;
        const translationKey = languageSelected as keyof typeof this.translation;
        const countryTranslations = this.translation[translationKey];

        return countryTranslations[key as keyof typeof countryTranslations];
    };

    public  getTranslationWithoutContext(key: string, language: string) {
        const translationKey = language as keyof typeof this.translation;
        const countryTranslations = this.translation[translationKey];

        return countryTranslations[key as keyof typeof countryTranslations];
    }
}
