import {Translations as ParentTranslations} from "../classes/Translations";

export default class GeneralTranslations extends ParentTranslations {
    readonly translation = {
        'es': {
            language: "Idioma",
            country: "País",
            english: "Inglés",
            spanish: "Español",
            french: "Francés",
            usa:'EE.UU',
            mexico:'México',
            canada:'Canadá',
            continue:'Ingresar',
            pageTitle: 'NSP Iniciar sesión',
            generalError: 'Algo salió mal. Póngase en contacto con el servicio al cliente para obtener ayuda.',
            redirectUrl: 'Esta página se ha movido. Vaya al sitio que desea visitar y continúe a la página de inicio de sesión. ¡Gracias!',
            loginFailure: 'El nombre de usuario o contraseña ingresada es incorrecta',


        },
        'en': {
            language: "Language",
            country: "Country",
            english: "English",
            spanish: "Spanish",
            french: "French",
            usa:'USA',
            mexico:'Mexico',
            canada:'Canada',
            continue:'Sign In',
            pageTitle: 'NSP Sign in',
            generalError: 'Something went wrong. Please contact customer service for assistance.',
            redirectUrl: 'This page has moved.  Please go to the site you\'d like to visit and continue to the sign in page. Thank you!',
            loginFailure: 'The Username or Password entered is incorrect',

        },
        'fr': {
            language: "Langue",
            country: "Pays",
            english: "Anglais",
            spanish: "Espagnol",
            french: "Français",
            usa:'Etats-Unis',
            mexico:'Mexique',
            canada:'Canada',
            continue:'Connexion',
            pageTitle: 'Connexion NSP',
            generalError: 'Quelque chose s\'est mal passé. Veuillez contacter le service client pour obtenir de l\'aide.',
            redirectUrl: 'Cette page a déplacé. Veuillez vous rendre sur le site que vous souhaitez visiter et continuer à la page de connexion. Merci!',
            loginFailure: 'Le nom d\'utilisateur ou le mot de passe entré est incorrect',

        }
    }

}


