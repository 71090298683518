import { ServiceResult } from "../../classes/PasswordValidation";
import SignInTranslations from "../../translations/SignInTranslations";
import GeneralTranslations from "../../translations/GeneralTranslations";
import { LoginManager } from "../../classes/login/LoginManager";

export class LoginProcessing {
    /** DEBUGGING DEVELOPMENT ONLY **/
    private override = false;

    private translation: SignInTranslations = new SignInTranslations();
    private loginManager: LoginManager;
    public messages: { type: string; text: string }[] = [];
    public toggleButton: boolean = false;
    public refreshReCaptcha: boolean = false;
    private captchaAttempts: { count: number; timestamp: number };
    public recaptchaToken: string = '';
    private setRecaptchaState: React.Dispatch<React.SetStateAction<boolean>>;

    constructor(language: string, returnUrl: string, recaptchaState: boolean, setRecaptchaState: React.Dispatch<React.SetStateAction<boolean>>) {
        this.loginManager = new LoginManager(language, returnUrl);
        this.captchaAttempts = { count: 0, timestamp: Date.now() };
        this.refreshReCaptcha = recaptchaState;
        this.setRecaptchaState = setRecaptchaState;
    }

    public async login(username: string, password: string): Promise<void> {
        this.toggleButton = true;

        if (this.override) {
            if (username === 'test-us') {
                this.messages = []
                this.addMessage('success', 'Override enabled');
            } else {
                this.addMessage('danger', 'Override enabled......................................... try test-us');
            }
            this.resetRecaptcha();
            return;
        }

        const serviceResult: ServiceResult = await this.loginManager.signIn(username, password, this.recaptchaToken);

        if (serviceResult.result && serviceResult.translation) {
            this.messages = []
            this.addMessage('success', serviceResult.translation);
        } else if (serviceResult.translation) {
            this.addMessage('danger', serviceResult.translation);
        }

        this.resetRecaptcha();
    }

    private resetRecaptcha() {
        const now = Date.now();
        if (now - this.captchaAttempts.timestamp > 30) {
            this.captchaAttempts = { count: 1, timestamp: now };
            this.setRecaptchaState(prevState => !prevState);
            this.toggleButton = false;
        } else if (this.captchaAttempts.count < 10) {
            this.captchaAttempts.count += 1;
            this.setRecaptchaState(prevState => !prevState);
            this.toggleButton = false;
        } else {
            this.addMessage('danger', this.translation.getTranslation('rate_limit_exceeded'));
            this.toggleButton = false;
        }
    }

    private addMessage(type: string, text: string): void {
        if (this.messages.length >= 3) {
            this.messages.shift();
        }
        this.messages.push({ type, text });
    }

    public setRecaptchaToken(token: string): void {
        this.recaptchaToken = token;
    }

    public checkAutofill(usernameField: HTMLInputElement | null, passwordField: HTMLInputElement | null): void {
        if (usernameField?.value && passwordField?.value) {
            this.toggleButton = false;
        }
    }
}
