import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from 'react';
import {Recaptcha} from "../Recaptcha/Recaptcha";
import {SiteSourceContext} from "../Origin/SiteSource";
import {ServiceResult} from "../../classes/PasswordValidation";
import {LoginManager} from "../../classes/login/LoginManager";
import ForgotPasswordTranslations from "../../translations/ForgotPasswordTranslations";
import GeneralTranslations  from "../../translations/GeneralTranslations";

let recaptchaToken: string;
export const ForgotPassword = () => {

    let usernameField = React.createRef<HTMLInputElement>();
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [toggleButton, setToggleButton] = useState(true)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const sourceState = useContext(SiteSourceContext)

    const loginManager: LoginManager = new LoginManager(sourceState.language, sourceState.returnUrl);
    const translation = new ForgotPasswordTranslations();
    const generalTranslations = new GeneralTranslations();

    /**
     * Page Title Management after load
     */
    useEffect(() => {
        document.title = generalTranslations.getTranslationWithoutContext('pageTitle', sourceState.language);
    });

    /**
     * Submit Form Hook
     */
    const reset = () => {
        setToggleButton(true);
        const username = usernameField.current?.value ? usernameField.current.value : '';

        loginManager.forgottenPassword(username, recaptchaToken).then((recoveryEmailSent: ServiceResult) => {
            console.log('loginmanager response',recoveryEmailSent);

            if (recoveryEmailSent.result && recoveryEmailSent.translation) {
                setSuccessMessage(recoveryEmailSent.translation);
                setErrorMessage('')
            } else if (recoveryEmailSent.translation) {
                setErrorMessage(recoveryEmailSent.translation)
                setSuccessMessage('');
            }

            //Reset ReCaptcha and Enable Button
            setRefreshReCaptcha((t) => {
                setToggleButton(false)
                return !t
            });
        })
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-header">

                    <img id="main-logo"
                         alt='Natures Sunshine'
                         src="https://res.cloudinary.com/natures-sunshine/image/upload/c_scale,w_512/natures-sunshine/logos/ns-logo-color.png"/>
                </div>
                <div>
                    <div id="error-message"
                         className={"alert alert-danger " + (errorMessage.length ? 'show-message' : '')}>{errorMessage}</div>
                    <div id="success-message"
                         className={"alert alert-success " + (successMessage.length ? 'show-message' : '')}>{successMessage}</div>
                </div>
                <div className="page-title">
                    {generalTranslations.getTranslation('page_content')}
                </div>
                <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    reset()
                }} method="POST">
                    <div className="form-group">
                        <input type="email"
                               className="form-control"
                               onChange={(e: ChangeEvent<HTMLInputElement>) => (e.target.value.length > 0) ? setToggleButton(false) : setToggleButton(true)}
                               id="email"
                               ref={usernameField}
                               placeholder={translation.getTranslation('email')}
                               aria-label="Email"/>
                    </div>
                    <div className="captcha-container form-group">

                        <Recaptcha
                            returnToken={(token: string) => {
                            recaptchaToken = token
                        }}
                            action="forgotpassword"
                            refreshTokenState={refreshReCaptcha}
                        />

                    </div>
                    <button
                        type="button"
                        id="btn-login"
                        className="btn btn-primary btn-block"
                        disabled={toggleButton}
                        onClick={reset}>
                        <span id="label-sign-in">{translation.getTranslation('reset')} </span>
                        <svg aria-hidden="true" focusable="false" className="icon-text" width="8px" height="12px"
                             viewBox="0 0 8 12"
                             version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)" fill="#FFFFFF">
                                    <polygon id="Shape"
                                             points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"></polygon>
                                </g>
                            </g>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    )
}
