import {AuthServiceAdapter, AuthServiceResponse} from "../../api/auth/AuthServiceAdapter";
import {AuthServiceFactory} from "./AuthServiceFactory";
import {DefaultAuthHandler} from "./DefaultAuthHandler";
import {AuthHandler} from "../../api/auth/AuthHandler";
import {ChangeEvent} from "react";
import {ServiceResult} from "../PasswordValidation";

/**
 * Login Manager handles auth service calls and post request processing
 */
export class LoginManager {

    private authHandler: AuthHandler;
    private authService: AuthServiceAdapter;

    /**
     * Get the service adapter by the redirect url
     * @param redirectUrl
     */
    protected static getAuthService(redirectUrl: string): AuthServiceAdapter {
        return AuthServiceFactory.getServiceAdapterByRedirectUrl(redirectUrl)
    }

    /**
     * Get the auth handler by the language and redirect url
     * @param language
     * @param redirectUrl
     * @protected
     */
    protected static getAuthHandler(language: string, redirectUrl: string): AuthHandler {
        // If we need the to change the default AuthHandler to handle a different post call logic
        // please create a factory and a new AuthHandler class
        return new DefaultAuthHandler(language, redirectUrl);
    }

    constructor(private language: string, private redirectUrl: string) {
        this.authHandler = LoginManager.getAuthHandler(language, redirectUrl);
        this.authService = LoginManager.getAuthService(redirectUrl);
    }

    /**
     * Password Validation Interface
     * @param token
     * @param password
     * @param reCaptcha
     */
    updatePassword(token: string, password: string, reCaptcha?: string): Promise<ServiceResult> {
        const updateAuthResult: Promise<AuthServiceResponse> = this.authService
            .passwordUpdate(token, password, this.redirectUrl, reCaptcha)

        return this.authHandler.updatePassword(updateAuthResult);
    }

    /**
     * Sign In Interface
     * @param username
     * @param password
     * @param recaptchaToken
     */
    public signIn(username: string, password: string, recaptchaToken?: string): Promise<ServiceResult> {
        const signInResult = this.authService.signIn(username, password, this.redirectUrl, this.language, recaptchaToken)

        return this.authHandler.signIn(signInResult);
    }

    /**
     * Forgotten Password Interface
     * @param username
     * @param recaptchaToken
     */
    public forgottenPassword(username: string, recaptchaToken?: string): Promise<ServiceResult> {

        const redirectWithLanguage = `${this.redirectUrl}/${this.language}`;
        const forgotPasswordResult: Promise<AuthServiceResponse> = this.authService
            .forgotPasswordEmail(username, redirectWithLanguage, this.language, recaptchaToken)

        return this.authHandler.forgottenPassword(forgotPasswordResult);
    }

    /**
     * Change Password Interface
     * @param email
     * @param oldPassword
     * @param newPassword
     * @param recaptchaToken
     */
    public changePassword(email: string, oldPassword: string, newPassword: string, recaptchaToken?: string): Promise<ServiceResult> {
        const changePasswordResult: Promise<AuthServiceResponse> = this.authService
            .changePassword(email, oldPassword, newPassword, this.redirectUrl, recaptchaToken)

        return this.authHandler.changePassword(changePasswordResult);

    }

    /**
     * Password Validation Interface
     * @param event
     */
    public passwordValidation(event: ChangeEvent<HTMLInputElement>): ServiceResult {
        return this.authHandler.validatePasswordWithTranslation(event, this.language);
    }

}
