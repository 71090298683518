import {NSPAuthService} from "../../api/auth/NSPAuthService";
import {AuthServiceAdapter} from "../../api/auth/AuthServiceAdapter";
import {CCSAuthService} from "../../api/auth/CCSAuthService";

export class AuthServiceFactory {
    /**
     * Get the service adapter by the redirect url
     * @param redirectUrl
     */
    public static getServiceAdapterByRedirectUrl(redirectUrl: string): AuthServiceAdapter {
        if (redirectUrl.includes("css")) {
            return this.getCCSAuthService();
        } else if (redirectUrl.includes("naturessunshine")) {
            return this.getNSPAuthService();
        }
        return this.getNSPAuthService();
    }

    public static getNSPAuthService(): AuthServiceAdapter {
        return new NSPAuthService();
    }

    public static getCCSAuthService(): AuthServiceAdapter {
        return new CCSAuthService();
    }
}