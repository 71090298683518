export interface APIInfo {
    url: string,
    header: {
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': string
        }
    }
}

export abstract class Api {
    /**
     * Set up the api url and api token
     * @param url
     * @param apiToken
     */
    constructor(private url:string, private apiToken: string) {
    }

    /**
     * Get the api info for the request headers
     * @param api
     * @param addHeaders
     * @protected
     */
    protected getApiInfo(api: string, addHeaders: {} = {}): APIInfo {


        return {
            url: this.url + api,
            header: {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': this.apiToken,
                    ...addHeaders,
                }
            },
        }
    }
}