import React, {ChangeEvent, createRef, FormEvent, useContext, useEffect, useState} from 'react';
import {Recaptcha} from "../Recaptcha/Recaptcha";
import {SiteSourceContext} from "../Origin/SiteSource";
import './ChangePassword.scss'
import {LoginManager} from "../../classes/login/LoginManager";
import GeneralTranslations from "../../translations/GeneralTranslations";
import ChangePasswordTranslations from "../../translations/ChangePasswordTranslations";

let recaptchaToken: string;
export const ChangePassword = () => {

    const [togglePassword, setTogglePassword] = useState('password')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [toggleButton, setToggleButton] = useState(true)
    const sourceState = useContext(SiteSourceContext)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const translation = new ChangePasswordTranslations();
    const generalTranslations = new GeneralTranslations();
    const loginManager: LoginManager = new LoginManager(sourceState.language, sourceState.returnUrl);

    let usernameField = React.createRef<HTMLInputElement>(),
        passwordOldField = createRef<HTMLInputElement>(),
        passwordNewField = createRef<HTMLInputElement>();

    /**
     * Submit Form Hook
     */
    const changePassword = () => {
        const username = usernameField.current?.value ? usernameField.current.value : '';
        const passwordOld = passwordOldField.current?.value ? passwordOldField.current.value : '';
        const passwordNew = passwordNewField.current?.value ? passwordNewField.current.value : '';
        loginManager.changePassword(username, passwordOld, passwordNew, recaptchaToken)
            .then((serviceResult) => {
                if (serviceResult.translation && serviceResult.result) {
                    setSuccessMessage(serviceResult.translation)
                } else if (serviceResult.translation) {
                    setErrorMessage(serviceResult.translation)
                    setSuccessMessage('')
                }
            });

        //Reset ReCaptcha and Enable Button
        setRefreshReCaptcha((t) => {
            setToggleButton(false)
            return !t
        });
    };

    /**
     * Page Title Management after load
     */
    useEffect(() => {
        document.title = generalTranslations.getTranslationWithoutContext('pageTitle', sourceState.language);
    });

    /**
     * Password Validation Interface Hook
     * @param event
     */
    function validatePassword(event: ChangeEvent<HTMLInputElement>) {
        const serviceResult = loginManager.passwordValidation(event);

        if (serviceResult.result) {
            setToggleButton(false);
            setErrorMessage('');
        } else if (serviceResult.translation) {
            //Error
            setErrorMessage(serviceResult.translation);
            setToggleButton(true)
        }
    }



    return (<>
            <div className="login-container">
                <div className="login-box">
                    <div className="login-header">

                        <img id="main-logo"
                             alt='Natures Sunshine'
                             src="https://res.cloudinary.com/natures-sunshine/image/upload/c_scale,w_512/natures-sunshine/logos/ns-logo-color.png"/>
                    </div>
                    <div>
                        <div id="error-message"
                             className={"alert alert-danger " + (errorMessage.length ? 'show-message' : '')}>{errorMessage}</div>
                        <div id="success-message"
                             className={"alert alert-success " + (successMessage.length ? 'show-message' : '')}>{successMessage}</div>
                    </div>
                    <form onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()} method="POST">
                        <div className='page-title'>
                            {translation.getTranslation('page_update_password')}
                        </div>
                        <div className="form-group">
                            <input type="email"
                                   className="form-control"
                                   id="email"
                                   ref={usernameField}
                                   placeholder={translation.getTranslation('email')}
                                   aria-label="Email"/>
                        </div>
                        <div className="form-group">
                            <input
                                ref={passwordOldField}
                                type={togglePassword}
                                className="form-control"
                                id="password"
                                placeholder={translation.getTranslation('old_password')}/>
                        </div>
                        <div className="form-group">
                            <input
                                ref={passwordNewField}
                                type={togglePassword}
                                onChange={validatePassword}
                                className="form-control"
                                id="password"
                                placeholder={translation.getTranslation('new_password')}/>
                        </div>
                        <div className='form-group'>
                            <div className='form-check'>
                                <label
                                    className='form-check-label'>{translation.getTranslation('checkbox_show_passwords')}</label>
                                <input className='form-check-input'
                                       onClick={() => togglePassword === 'password' ? setTogglePassword('text') : setTogglePassword('password')}
                                       type='checkbox'/>
                            </div>
                        </div>

                        <div className="captcha-container form-group">


                            <Recaptcha
                                returnToken={(token: string) => {
                                    recaptchaToken = token
                                }}
                                action='changepassword'
                                refreshTokenState={refreshReCaptcha}
                            />


                        </div>
                        <button
                            type="submit"
                            id="btn-login"
                            className="btn btn-primary btn-block"
                            disabled={toggleButton}
                            onClick={changePassword}>
                            <span id="label-sign-in">{translation.getTranslation('button_change_password')} </span>
                            <svg aria-hidden="true" focusable="false" className="icon-text" width="8px" height="12px"
                                 viewBox="0 0 8 12"
                                 version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)"
                                       fill="#FFFFFF">
                                        <polygon id="Shape"
                                                 points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"></polygon>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}
