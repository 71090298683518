import { useCallback, useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

export const Recaptcha = forwardRef((props: {
  action: string;
  returnToken: Function;
  refreshTokenState: boolean;
}, ref?) => {
  const recaptchaSiteKey =
      typeof process.env.REACT_APP_RECAPTCHA_SITE_KEY != "undefined"
          ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
          : "NO RECAPTCHA KEY IN ENV FILE";

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [manualRefreshTokenState, setManualRefreshTokenState] = useState(props.refreshTokenState);
  const [showRecaptcha, setShowRecaptcha] = useState(true);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setShowRecaptcha(true);
      setManualRefreshTokenState((t) => !t);
    }, 100000);

    return () => {
      clearInterval(intervalID);
    };
  }, [recaptchaToken]);

  useImperativeHandle(ref, () => ({
    refreshCaptcha() {
      setManualRefreshTokenState((t) => !t);
    }
  }));

  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
    props.returnToken(token);
  }, [props]);

  return (
      <>
        {showRecaptcha && (
            <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
              <GoogleReCaptcha onVerify={onVerify} action={props.action} refreshReCaptcha={manualRefreshTokenState} />
              <input type="hidden" name="g-token" id="g-token" value={recaptchaToken} />
            </GoogleReCaptchaProvider>
        )}
      </>
  );
});
