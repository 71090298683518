import {AuthServiceAdapter, AuthServiceResponse} from "./AuthServiceAdapter";
import {APIInfo} from "../Api";


export class NSPAuthService extends AuthServiceAdapter {


    constructor() {
        const url = (typeof process.env.REACT_APP_API_ACCOUNT != "undefined") ? process.env.REACT_APP_API_ACCOUNT : 'undefined';
        const apiToken = (typeof process.env.REACT_APP_API_ACCOUNT_TOKEN != "undefined") ? process.env.REACT_APP_API_ACCOUNT_TOKEN : 'undefined';

        super(url, apiToken);
    }

    /**
     * Sign In
     * @param username
     * @param password
     * @param redirectUrl
     * @param language
     * @param recaptchaToken
     */
    public signIn(username: string, password: string, redirectUrl: string, language: string, recaptchaToken: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo(
            '/api/v1/authentication/signin',
            {
                'Authorization': `Basic ` + btoa(`${username}:${password}`)
            });

        const body =
            {
                "userid": username,
                "password": password,
                "reCaptchaToken": recaptchaToken,
                redirectUrl,
                "languageIsoCode": language
            }

        return fetch(info.url, {
            method: 'POST',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then((data: Response) => {
            return data.json();
        })
    }

    /**
     * Forgot Password Email
     * @param email
     * @param redirectUrl
     * @param language
     * @param recaptcha
     */
    public forgotPasswordEmail(email: string, redirectUrl: string, language: string, recaptcha: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo('/api/v1/authentication/forgotpassword');

        const body = {
            email: email,
            redirectUrl: redirectUrl,
            reCaptchaToken: recaptcha,
            language: language
        }

        return fetch(info.url, {
            method: 'POST',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then((data: Response) => {
            return data.json();
        })
    }

    /**
     * Forgot Password Update
     * @param token
     * @param password
     * @param redirectUrl
     * @param reCaptchaToken
     */
    public passwordUpdate(token: string, password: string, redirectUrl: string, reCaptchaToken: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo('/api/v1/authentication/changensppassword');
        const body = {
            token: token,
            newPassword: password,
            reCaptchaToken: reCaptchaToken,
            redirectUrl
        }

        return fetch(info.url, {
            method: 'PUT',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then(data => {
            return data.json();
        })
    }

    /**
     * Change Password
     * @param email
     * @param old_password
     * @param new_password
     * @param redirectUrl
     * @param reCaptchaToken
     * @returns
     */
    public changePassword(email: string, old_password: string, new_password: string, redirectUrl: string, reCaptchaToken: string): Promise<AuthServiceResponse> {
        const info: APIInfo = this.getApiInfo('/api/v1/authentication/updatepassword');

        const body = {
            userid: email,
            oldPassword: old_password,
            newPassword: new_password,
            reCaptchaToken,
            redirectUrl
        }

        return fetch(info.url, {
            method: 'PUT',
            headers: info.header.headers,
            body: JSON.stringify(body)
        }).then((response: Response) => {
            return response.json();
        })
    }
}
