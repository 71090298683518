import {PasswordValidation, ServiceResult} from "../../classes/PasswordValidation";
import {AuthServiceResponse} from "./AuthServiceAdapter";

/**
 * Manage the authentication service response and utilize translations to return a ServiceResult.
 */
export abstract class AuthHandler extends PasswordValidation {

    public abstract updatePassword(updateAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult>

    public abstract signIn(signInAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult>


    public abstract forgottenPassword(forgotPasswordAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult>

    public abstract changePassword(changePasswordAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult>

}