import { AuthServiceResponse } from "../../api/auth/AuthServiceAdapter";
import { ServiceResult } from "../PasswordValidation";
import { AuthHandler } from "../../api/auth/AuthHandler";

import UpdatePasswordTranslations from "../../translations/UpdatePasswordTranslations";
import SignInTranslations from "../../translations/SignInTranslations";
import ChangePasswordTranslations from "../../translations/ChangePasswordTranslations";
import ForgotPasswordTranslations from "../../translations/ForgotPasswordTranslations";
import GeneralTranslations from "../../translations/GeneralTranslations";


export class DefaultAuthHandler extends AuthHandler {

    private generalTranslation: GeneralTranslations
    //private processingSubSystem = '/redirect-processing/'
    private processingSubSystem = ''

    constructor(private language: string, private redirectUrl: string) {
        super();
        const subDomainPattern = /^(?!www\.)[a-z0-9-]+\.naturessunshine/i;


        // Check pattern to find for sub-domains and check foR latam-reports, if not add www
        if (!this.redirectUrl?.includes('www.naturessunshine')
            && !subDomainPattern.test(this.redirectUrl)
            && this.redirectUrl?.includes('latam') === false) {
            this.redirectUrl = `www.${this.redirectUrl}`;
        }

        this.generalTranslation = new GeneralTranslations();
    }

    /**
     * Update Password post call processing and translation
     * @param updateAuthResult
     */
    public updatePassword(updateAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult> {
        const Translation = new UpdatePasswordTranslations()
        return updateAuthResult.then((response: AuthServiceResponse): ServiceResult => {
            if (response.success) {

                setTimeout(() => {
                    if (this.redirectUrl.includes('dev.naturessunshine')) {
                        window.location.href = `http://${this.redirectUrl}/${this.language}`
                    } else {
                        window.location.href = `/${this.redirectUrl}/${this.language}`

                    }
                }, 10);

                return {
                    result: true,
                    translationKey: Translation.getTranslationWithoutContext('passwordSuccess', this.language)
                } as ServiceResult
            }
            switch (response.message.toLowerCase()) {
                case 'invalid redirect url':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('redirectUrl', this.language)
                    } as ServiceResult
                case 'credentialsdonotmatch':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('loginFailure', this.language)
                    } as ServiceResult
                default:
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('generalError', this.language)
                    } as ServiceResult

            }
        })
    }

    /**
     * Sign In post call processing and translation
     * @param signInAuthResult
     */
    public signIn(signInAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult> {
        const Translation = new SignInTranslations()
        return signInAuthResult.then((data: AuthServiceResponse): ServiceResult => {
            //On Success, Redirect back to website with login token included
            if (data.success) {
                // Redirect
                const language = this.language === 'en' ? '' : this.language;
                const urlPrefix = this.redirectUrl.includes('dev.naturessunshine') ? 'http://' : '//';
                const languageSegment = language ? `/${language}` : '';
                const hasVibeoffice = this.redirectUrl.includes('vibeoffice.com');
                const hasMynsp = this.redirectUrl.includes('mynsp.com');
                const hasLatam = this.redirectUrl.includes('latam');
                let redirectProcessing = '';

                if (hasVibeoffice || hasMynsp || hasLatam) {
                    redirectProcessing = `${urlPrefix}${this.redirectUrl}${languageSegment}`;
                } else {
                    redirectProcessing = `${urlPrefix}${this.redirectUrl}${languageSegment}/${this.processingSubSystem}`;
                }

                const queryParams = new URLSearchParams({
                    email: data.userProfile.email,
                    ucode: data.userProfile.authenticationToken,
                    userId: data.userProfile.accountNumber,
                }).toString();

                window.location.assign(`${redirectProcessing}?${queryParams}`);
                return {
                    result: true,
                    translation: Translation.getTranslationWithoutContext('loginSuccess', this.language)
                } as ServiceResult
            }

            switch (data?.errorCode) {
                case 'REDIRECT_URL_IS_INVALID':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('redirectUrl', this.language)
                    } as ServiceResult
                case 'NOT_AUTHENTICATED':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('loginFailure', this.language)
                    } as ServiceResult
                case 'ACCOUNT_DIFFERENT_MARKET':
                    return {
                        result: false,
                        translation: data?.message
                    } as ServiceResult
                default:
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('generalError', this.language)
                    } as ServiceResult

            }
        })
    }

    /**
     * Forgotten Password post call processing and translation
     * @param forgotPasswordAuthResult
     */
    public forgottenPassword(forgotPasswordAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult> {
        const Translation = new ForgotPasswordTranslations()
        return forgotPasswordAuthResult.then((data: AuthServiceResponse) => {
            // return translations
            if (data.success) {
                return {
                    result: true,
                    translation: Translation.getTranslationWithoutContext('emailSuccess', this.language)
                }
            }

            switch (data.message.toLowerCase()) {
                case 'invalid redirect url':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('redirectUrl', this.language)
                    } as ServiceResult
                case 'credentialsdonotmatch':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('loginFailure', this.language)
                    } as ServiceResult
                default:
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('generalError', this.language)
                    } as ServiceResult

            }
        });
    }

    /**
     * Change Password post call processing and translation
     * @param changePasswordAuthResult
     */
    public changePassword(changePasswordAuthResult: Promise<AuthServiceResponse>): Promise<ServiceResult> {
        const Translation = new ChangePasswordTranslations()
        return changePasswordAuthResult.then((data: AuthServiceResponse) => {
            console.log('handler', data)

            //On Success, Redirect back to website with login token included
            if (data.success) {
                setTimeout(() => {
                    window.location.href = `/${this.redirectUrl}/${this.language}`;
                }, 1500)

                return {
                    result: true,
                    translationKey: 'passwordSuccess',
                    translation: Translation.getTranslationWithoutContext('passwordSuccess', this.language)
                }
            }
            switch (data.message.toLowerCase()) {
                case 'invalid redirect url':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('redirectUrl', this.language)
                    } as ServiceResult
                case 'credentialsdonotmatch':
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('loginFailure', this.language)
                    } as ServiceResult
                default:
                    return {
                        result: false,
                        translation: this.generalTranslation.getTranslationWithoutContext('generalError', this.language)
                    } as ServiceResult

            }
        })
    }
}
