import React, {ReactNode, useState} from "react";
import {DomainSource} from "./DomainSource";

export interface SiteSource {
    language: string,
    returnUrl: string,
    updatedViaUrl: boolean | undefined
}

const siteSourceModel: SiteSource = {language: 'en', returnUrl: 'naturessunshine.com', updatedViaUrl: false}

// Default values to start state
export const SiteSourceContext = React.createContext(siteSourceModel);
export const SiteSourceContextUpdate = React.createContext((language: string | undefined, redirect: string | undefined, updateViaUrl: boolean | undefined) => {
    console.log('Empty language Function')
});


export function SiteSourceProvider(props: { children: ReactNode }) {
    const [siteSourceState, setSiteSource] = useState<SiteSource>(siteSourceModel);

    /**
     * Update Language or Redirect URL
     * @param language
     * @param redirect
     */
    const siteSourceUpdate = (language: undefined | string, returnUrl: undefined | string, updatedViaUrl: boolean | undefined) => {
        if (language !== undefined && returnUrl !== undefined) {
            setSiteSource({returnUrl, language, updatedViaUrl})
        } else if (language !== undefined) {
            setSiteSource({...siteSourceState, language, updatedViaUrl})
        } else if (returnUrl !== undefined) {
            setSiteSource({...siteSourceState, returnUrl, updatedViaUrl})
        }
    }


    return (<SiteSourceContext.Provider value={siteSourceState}>
        <SiteSourceContextUpdate.Provider value={siteSourceUpdate}>
            <DomainSource>
                {props.children}
            </DomainSource>
        </SiteSourceContextUpdate.Provider>
    </SiteSourceContext.Provider>)

}

