import {Translations as ParentTranslations} from "../classes/Translations";

export default class ForgotPasswordTranslations extends ParentTranslations {
    readonly translation = {
        'es': {
            reset: 'Enviar correo electrónico de reinicio',
            username:'Nombre de usuario',
            page_content: "Enviaremos un enlace de restablecimiento de contraseña a la dirección de correo electrónico asociada con su cuenta.",
            email: 'Correo electrónico',
            emailSuccess: 'Se ha enviado un correo electrónico de recuperación',
            emailFailure: 'No pudimos enviar un correo electrónico de recuperación de la cuenta al nombre de usuario proporcionado. Póngase en contacto con el servicio de atención al cliente para obtener asistencia.',

        },
        'en': {
            reset: 'Send Reset Email',
            username: 'Username',
            password: 'Password',
            page_content: "We'll send a password reset link to the email address associated with your account.",
            email: 'Email',
            emailSuccess: 'Recovery email has been sent',
            emailFailure: 'We were unable to send an account recovery e-mail to the username provided. Please contact customer support for assistance.',

        },
        'fr': {
            reset: 'Envoyer un e-mail de réinitialisation',
            username: 'Nom d\'usager',
            email: 'E-mail',
            page_content: "Nous enverrons un lien de réinitialisation de mot de passe vers l'adresse e-mail associée à votre compte.",
            emailSuccess: 'Se ha enviado un correo electrónico de recuperación',
            emailFailure: 'Nous n\'avons pas pu envoyer un e-mail de récupération de compte au nom d\'utilisateur fourni. Veuillez contacter l\'assistance client pour obtenir de l\'aide.',

        }

    }
}
