import {Translations as ParentTranslations} from "../classes/Translations";

export default class UpdatePasswordTranslations extends ParentTranslations {
    readonly translation = {
        'es': {
            'passwordAllowedCharacters': 'Ingrese su nueva contraseña que tenga al menos 8 caracteres y contenga 1 número.',
            'passwordAllowedLength': 'Ingrese su nueva contraseña que tenga al menos 8 caracteres y contenga 1 número.',
            'new_password': 'Nueva contraseña',
            'button_new_password': 'Guardar una nueva contraseña',
            'page_content': 'Ingrese su nueva contraseña que tenga al menos 8 caracteres y contenga 1 número.',
            'passwordSuccess': 'La contraseña ha sido actualizada.',
            'passwordFailure': 'We were unable to update your password, please try again or contact your system administrator',
            email: 'Correo electrónico',
            loginFailure: 'El nombre de usuario o contraseña ingresada es incorrecta',




        },
        'en': {
            'passwordAllowedCharacters': 'Password may only contain characters, numbers, and symbols.',
            'passwordAllowedLength': 'Password must have a length of at least eight characters.',
            'new_password': 'New Password',
            'button_new_password': 'SAVE NEW PASSWORD',
            'page_content': 'Enter your new password that is at least 8 characters long and contains a number.',
            'passwordSuccess': 'Password has been updated.',
            'passwordFailure': 'We were unable to update your password, please try again or contact your system administrator',
            email: 'Email',
            loginFailure: 'The Username or Password entered is incorrect',


        },
        'fr': {
            'passwordAllowedCharacters': 'Entrez votre nouveau mot de passe qui mesure au moins 8 caractères et contient 1 nombre.',
            'passwordAllowedLength': 'Entrez votre nouveau mot de passe qui mesure au moins 8 caractères et contient 1 nombre.',
            'new_password': 'Nouveau mot de passe',
            'button_new_password': 'Enregistrer un nouveau mot de passe',
            'page_content': 'Entrez votre nouveau mot de passe qui mesure au moins 8 caractères et contient 1 nombre.',
            'passwordSuccess': 'Le mot de passe a été mis à jour.',
            'passwordFailure': 'We were unable to update your password, please try again or contact your system administrator',
            email: 'E-mail',
            loginFailure: 'Le nom d\'utilisateur ou le mot de passe entré est incorrect',


        }

    }
}
