import React, {ChangeEvent, createRef, FormEvent, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Recaptcha} from "../Recaptcha/Recaptcha";
import {SiteSourceContext} from "../Origin/SiteSource";
import SignInTranslations from "../../translations/SignInTranslations";
import './Login.scss';
import {LoginProcessing} from "./LoginProcessing";
import GeneralTranslations from "../../translations/GeneralTranslations";

type RecaptchaRef = {
    refreshCaptcha: () => void;
};

export const Login = () => {
    const sourceState = useContext(SiteSourceContext);
    const [toggleButton, setToggleButton] = useState(true);
    const translation = useMemo(() => new SignInTranslations(), []);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const notificationContainerRef = useRef<HTMLDivElement>(null);
    const recaptchaRef = useRef<RecaptchaRef>(null);

    const [loginProcessing] = useState(() => new LoginProcessing(sourceState.language, sourceState.returnUrl, refreshReCaptcha, setRefreshReCaptcha));

    let usernameField = createRef<HTMLInputElement>(),
        passwordField = createRef<HTMLInputElement>();

    // Submit Form Hook
    const login = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const username: string = usernameField.current?.value ? usernameField.current.value : '';
        const password: string = passwordField.current?.value ? passwordField.current.value : '';

        if (username.length === 0 || password.length === 0) {
            setToggleButton(false);
            return;
        }

        await loginProcessing.login(username, password);
        adjustContainerHeight()

        setToggleButton(loginProcessing.toggleButton);

        // Manually refresh reCAPTCHA if needed
        if (recaptchaRef.current) {
            recaptchaRef.current.refreshCaptcha();
        }
    };
    const adjustContainerHeight = () => {
        const intervalId = setInterval(() => {
            if (notificationContainerRef.current) {
                const notifications = notificationContainerRef.current.children;
                let maxHeight = 0;

                for (let i = 0; i < notifications.length; i++) {
                    const notificationHeight = notifications[i].clientHeight;
                    if (notificationHeight > maxHeight) {
                        maxHeight = notificationHeight;
                    }
                }

                if (maxHeight > 0) {
                    notificationContainerRef.current.style.height = `${maxHeight}px`;
                    clearInterval(intervalId); // Stop checking once the adjustment is made
                }
            }
        }, 100); // Check every 500 milliseconds
    };


    // Page Title Management Hook
    useEffect(() => {
        const generalTranslations = new GeneralTranslations();
        document.title = generalTranslations.getTranslationWithoutContext('pageTitle', sourceState.language);
    }, [sourceState.language]);

    // Focus on the username field and check autofill when the component mounts
    useEffect(() => {
        usernameField.current?.focus();
        setToggleButton(loginProcessing.toggleButton);

    }, [usernameField]);

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-header">
                    <img id="main-logo"
                         alt='Natures Sunshine'
                         src="https://res.cloudinary.com/natures-sunshine/image/upload/c_scale,w_512/natures-sunshine/logos/ns-logo-color.png"/>
                </div>
                <div className={`notification-container ${(loginProcessing.messages.length ? ' show' : '')}`}
                     ref={notificationContainerRef}>
                    {loginProcessing.messages.map((message, index) => (
                        <div key={index} className={`alert alert-${message.type} notification`}>
                            {message.text}
                        </div>
                    ))}
                </div>

                <form onSubmit={login}>
                    <div className="form-group">
                        <input type="text"
                               className="form-control"
                               id="email"
                               ref={usernameField}
                               placeholder={translation.getTranslation('username')}
                               aria-label="Email"/>
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                (e.target.value.length > 0) ? setToggleButton(false) : setToggleButton(true);
                            }}
                            id="password"
                            ref={passwordField}
                            placeholder={translation.getTranslation('password')}/>
                    </div>
                    <div className='change-password-container'>
                        <a href={`/changepassword/${sourceState.returnUrl}/${sourceState.language}`}>{translation.getTranslation('change_password')}</a>
                    </div>
                    <div className="forgot-password-container">
                        <a id="hyp-forgot-password"
                           href={`/forgotpassword/${sourceState.returnUrl}/${sourceState.language}`}>{translation.getTranslation('forgotten_password')}</a>
                    </div>

                    <div className="captcha-container form-group">
                        <Recaptcha
                            ref={recaptchaRef}
                            action='login'
                            returnToken={(token: string) => loginProcessing.setRecaptchaToken(token)}
                            refreshTokenState={refreshReCaptcha}/>
                    </div>
                    <button
                        type="submit"
                        id="btn-login"
                        className="btn btn-primary btn-block"
                        disabled={toggleButton}>
                        <span id="label-sign-in">{translation.getTranslation('signin')} </span>
                        <svg aria-hidden="true" focusable="false" className="icon-text" width="8px"
                             height="12px"
                             viewBox="0 0 8 12"
                             version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)"
                                   fill="#FFFFFF">
                                    <polygon id="Shape"
                                             points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"></polygon>
                                </g>
                            </g>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    );
};
