import {Translations as ParentTranslations} from "../classes/Translations";

export default class ChangePasswordTranslations extends ParentTranslations {
    readonly translation = {
        'es': {
            'username': 'Nombre de usuario',
            'new_password': 'Nueva contraseña',
            'old_password': 'Contraseña anterior',
            'button_change_password': 'Guardar una nueva contraseña',
            'checkbox_show_passwords': 'Mostrar contraseña',
            'passwordAllowedCharacters': 'Ingrese su nueva contraseña que tenga al menos 8 caracteres y contenga 1 número.',
            'passwordAllowedLength': 'Ingrese su nueva contraseña que tenga al menos 8 caracteres y contenga 1 número.',
            'passwordSuccess': 'La contraseña ha sido actualizada.',
            'page_update_password': 'Actualice su contraseña.',
            email: 'Correo electrónico',
        },
        'en': {
            'username': 'Username',
            'new_password': 'New Password',
            'old_password': 'Current Password',
            'button_change_password': 'Save New Password',
            'checkbox_show_passwords': 'Show passwords',
            'passwordAllowedCharacters': 'Enter your new password that is at least 8 characters long and contains a number.',
            'passwordAllowedLength': 'Enter your new password that is at least 8 characters long and contains a number.',
            'passwordSuccess': 'Password has successfully been updated.',
            'passwordFailure': 'We were unable to update your password, please try again or contact your system administrator',
            'page_update_password': 'Update your password.',
            email: 'Email',
        },
        'fr': {
            'username': 'Nom d\'usager',
            'new_password': 'Nouveau mot de passe',
            'old_password': 'Ancien mot de passe',
            'button_change_password': 'Enregistrer un nouveau mot de passe',
            'checkbox_show_passwords': 'Montrer le mot de passe',
            'passwordAllowedCharacters': 'Entrez votre nouveau mot de passe qui mesure au moins 8 caractères et contient 1 nombre.',
            'passwordAllowedLength': 'Entrez votre nouveau mot de passe qui mesure au moins 8 caractères et contient 1 nombre.',
            'passwordSuccess': 'Le mot de passe a été mis à jour.',
            'page_update_password': 'Mettez à jour votre mot de passe.',
            email: 'E-mail',
        },
    }

}
