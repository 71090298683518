import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {SiteSourceContext, SiteSourceContextUpdate} from "./SiteSource";

export const DomainSource = (props: { children: ReactNode }) => {
    let {redirect, language} = useParams();

    const updateSourceState = useContext(SiteSourceContextUpdate);
    const sourceState = useContext(SiteSourceContext);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // If already updated via URL, don't keep overwriting variable values
        if (sourceState.updatedViaUrl === true) {
            setIsLoaded(true);
            return;
        }

        let updateLanguage = false, updateRedirect = false;

        // Check For Updates
        if (typeof language !== 'undefined' && language !== sourceState.language) {
            updateLanguage = true;
        }
        if (typeof redirect !== 'undefined' && redirect !== sourceState.returnUrl) {
            updateRedirect = true;
        }

        // Update All Info At Once
        if (updateRedirect && updateLanguage) {
            updateSourceState(language, redirect, true);
        } else if (updateLanguage) {
            updateSourceState(language, undefined, true);
        } else if (updateRedirect) {
            updateSourceState(undefined, redirect, true);
        }

        setIsLoaded(true);
    }, [sourceState, updateSourceState, language, redirect]);

    // Display a loading indicator until the URL parameters are loaded
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return <>{props.children}</>;
};
