import {ChangeEvent} from "react";
import UpdatePasswordTranslations from "../translations/UpdatePasswordTranslations";

export interface ServiceResult {
    result: boolean,
    error?: string
    translationKey?: string
    translation?: string
}

export abstract class PasswordValidation {

    public validatePasswordWithTranslation(event: ChangeEvent<HTMLInputElement>, language: string): ServiceResult {
        const Translation = new UpdatePasswordTranslations();
        let serviceResponse: ServiceResult = this.validatePasswordEvent(event);

        if (serviceResponse.translationKey) {
            serviceResponse.translation = Translation.getTranslationWithoutContext(serviceResponse.translationKey, language);
        }

        return serviceResponse;
    }


    protected validatePasswordEvent(event: ChangeEvent<HTMLInputElement>): ServiceResult {

        const pass = (event.target as HTMLInputElement).value;
        const reg = /^.*(?=.{8,25})(?=.*[a-zA-Z])(?=.*\d).*$/;

        // Check Password Length
        if (pass.length < 8) {
            return {
                result: false,
                error: "Password must be at least eight characters in length",
                translationKey: "passwordAllowedLength"
            } as ServiceResult
        }

        // Check RegEx
        if (reg.test(pass)) {
            return {
                result: true,
                translationKey: 'passwordSuccess'
            } as ServiceResult
        } else {
            return {
                result: false,
                error: 'Password must include only letters, numbers, or symbols',
                translationKey: "passwordAllowedCharacters"
            } as ServiceResult
        }
    }
}
