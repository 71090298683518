import React, {ChangeEvent, createRef, FormEvent, useContext, useEffect, useState} from 'react';
import {Recaptcha} from "../Recaptcha/Recaptcha";
import {SiteSourceContext} from "../Origin/SiteSource";
import {useParams} from "react-router-dom";
import {LoginManager} from "../../classes/login/LoginManager";
import {ServiceResult} from "../../classes/PasswordValidation";
import UpdatePasswordTranslations from "../../translations/UpdatePasswordTranslations";
import GeneralTranslations from "../../translations/GeneralTranslations";

let recaptchaToken: string;
export const UpdatePassword = () => {
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [toggleButton, setToggleButton] = useState(true)
    const sourceState = useContext(SiteSourceContext)
    const passwordField = createRef<HTMLInputElement>();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const loginManager: LoginManager = new LoginManager(sourceState.language, sourceState.returnUrl);
    const translation = new UpdatePasswordTranslations();
    const generalTranslations = new GeneralTranslations();

    let {token} = useParams();

    /**
     * Password Validation Hook
     * @param event
     */
    function validatePassword(event: ChangeEvent<HTMLInputElement>) {
        const serviceResult: ServiceResult = loginManager.passwordValidation(event);

        if (serviceResult.result) {
            setSuccessMessage('')
            setToggleButton(false);
            setErrorMessage('');
        } else if (serviceResult.translation) {
            //Error
            setSuccessMessage('')
            setErrorMessage(serviceResult.translation);
            setToggleButton(true)
        }
    }

    /**
     * Page Title Management after load
     */
    useEffect(() => {
        document.title = generalTranslations.getTranslationWithoutContext('pageTitle', sourceState.language);
    });

    /**
     * Submit Form Hook
     */
    const login = () => {
        setToggleButton(true);

        const password = passwordField.current?.value ? passwordField.current.value : '';

        if (!token) {
            setErrorMessage(translation.getTranslation('passwordFailure'));
            setSuccessMessage('');
            return;
        }

        loginManager.updatePassword(token, password, recaptchaToken)
            .then((serviceResponse) => {
                // Update Translations
                if (serviceResponse.result && serviceResponse.translation) {
                    setSuccessMessage(serviceResponse.translation);
                    setErrorMessage('');
                } else if (serviceResponse.translation) {
                    setErrorMessage(serviceResponse.translation);
                    setSuccessMessage('');
                }

                //Enable Button and Refresh ReCaptchaToken
                setRefreshReCaptcha((t) => {
                    setToggleButton(false)
                    return !t
                });
            })
    };

    return (<>
            <div className="login-container">
                <div className="login-box">
                    <div className="login-header">

                        <img id="main-logo"
                             alt='Natures Sunshine'
                             src="https://res.cloudinary.com/natures-sunshine/image/upload/c_scale,w_512/natures-sunshine/logos/ns-logo-color.png"/>
                    </div>
                    <div>
                        <div id="error-message"
                             className={"alert alert-danger " + (errorMessage.length ? 'show-message' : '')}>{errorMessage}</div>
                        <div id="success-message"
                             className={"alert alert-success " + (successMessage.length ? 'show-message' : '')}>{successMessage}</div>
                    </div>
                    <div className="page-title">
                        {translation.getTranslation('page_content')}
                    </div>
                    <form onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()} method="POST">
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                onChange={validatePassword}
                                ref={passwordField}
                                placeholder={translation.getTranslation('new_password')}/>
                        </div>
                        <div className="captcha-container form-group">

                            <Recaptcha action='updatepassword' returnToken={(token: string) => recaptchaToken = token}
                                       refreshTokenState={refreshReCaptcha}/>

                        </div>
                        <button
                            type="submit"
                            id="btn-login"
                            className="btn btn-primary btn-block"
                            disabled={toggleButton}
                            onClick={login}>
                            <span id="label-sign-in">{translation.getTranslation('button_new_password')} </span>
                            <svg aria-hidden="true" focusable="false" className="icon-text" width="8px" height="12px"
                                 viewBox="0 0 8 12"
                                 version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)"
                                       fill="#FFFFFF">
                                        <polygon id="Shape"
                                                 points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"></polygon>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}
