import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Login} from './components/Signin/Login';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {ForgotPassword} from './components/ForgotPassword/ForgotPassword';
import {ChangePassword} from "./components/ChangePassword/ChangePassword";
import {UpdatePassword} from "./components/ForgotPasswordUpdate/UpdatePassword";
import {Oops} from "./components/Oops/Oops";
import {SiteSourceProvider} from "./components/Origin/SiteSource";


function App() {
    useEffect(() => {
        if (document.title.includes('React')) {
            document.title = 'Natures Sunshine';
        }
    }, []);

    const routerItems = createBrowserRouter([
        {
            path: '/',
            element: <SiteSourceProvider><Oops/></SiteSourceProvider>
        },
        {
            path: '/:redirect?/:language?',
            element: <SiteSourceProvider><Login/></SiteSourceProvider>,
        },
        {
            path: '/forgotpassword/:redirect/:language',
            element: <SiteSourceProvider><ForgotPassword/></SiteSourceProvider>,
        },
        {
            path: '/forgotpassword/:token/:redirect/:language',
            element: <SiteSourceProvider><UpdatePassword/></SiteSourceProvider>
        },
        {
            path: '/changepassword/:redirect/:language',
            element: <SiteSourceProvider><ChangePassword/></SiteSourceProvider>
        },
    ]);

    const Router = () => {
        return <RouterProvider router={routerItems}/>;
    };

    return (
        <Router/>
    );
}

export default App;
